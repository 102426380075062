<template>
  <div class="form">
    <div class="form__wrap" @submit.prevent="submit">
      <div class="form__content">
        <h1 class="form__title">Тестовая форма оплаты</h1>

        <button type="submit" @click="submit" class="form__btn form__btn--margin">
            Оплатить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { AcceptDemoPayment } from '../api';

export default {
  name: 'home',
  methods: {
    async submit() {
      const response = await AcceptDemoPayment(this.$route.query.paymentId);
      if (response.url) {
        window.location = response.url;
      }
    },
  },
};
</script>
<style scoped>
.form_input {
  margin-right: 0.6em;
}
textarea {
  font-size: 16px;
}
</style>
